module.exports = {
  catalogUrl: null,
  catalogTitle: "STAC Browser",
  allowExternalAccess: true, // Must be true if catalogUrl is not given
  allowedDomains: [],
  detectLocaleFromBrowser: true,
  storeLocale: true,
  locale: "en",
  fallbackLocale: "en",
  supportedLocales: [
    "de",
    //      "de-CH",
    "es",
    "en",
    //      "en-GB",
    //      "en-US",
    "fr",
    //      "fr-CA",
    //      "fr-CH",
    "it",
    //      "it-CH",
    "ro",
    "ja",
    "pt",
    //      "pt-BR"
  ],
  apiCatalogPriority: null,
  useTileLayerAsFallback: true,
  displayGeoTiffByDefault: false,
  buildTileUrlTemplate: ({ href, asset }) =>
    "https://tiles.rdnt.io/tiles/{z}/{x}/{y}@2x?url=" +
    encodeURIComponent(asset.href.startsWith("/vsi") ? asset.href : href),
  stacProxyUrl: null,
  pathPrefix: "/",
  historyMode: "history",
  cardViewMode: "cards",
  cardViewSort: "asc",
  showKeywordsInItemCards: false,
  showKeywordsInCatalogCards: false,
  showThumbnailsAsAssets: false,
  geoTiffResolution: 128,
  redirectLegacyUrls: false,
  itemsPerPage: 12,
  defaultThumbnailSize: null,
  maxPreviewsOnMap: 50,
  crossOriginMedia: null,
  requestHeaders: {},
  requestQueryParameters: {},
  preprocessSTAC: null,
  authConfig: null,
  showDefaultSearch: true,
  defaultStacApi: "https://mfvyi7me4i.execute-api.us-west-2.amazonaws.com/v1",
  defaultCollection: "videostac",
  signedUrlServer:
    "https://06f8uw343d.execute-api.us-west-2.amazonaws.com/prod",
  esriApiKey:
    "AAPK6e6690d477354f6a9a3d0ce8125fef0bePkhWX3TW-jXqoEQYDYcihz7w5fhyPyqHiJqtifmfukOSCY_yAQRdvUYzKGNWsnH",
  searchPageTitle: "PG&E Aerial Patrol Search",
  arcGisClientId: "U9ctAVBzfA85YV2i",
  arcGisPortalItemId: "876108cb804246929621ad49be78999c",
  featureServices: [
    {
      "title": "Gas Transmission Lines",
      "url": "https://services3.arcgis.com/u5GUsJeBVXYA7Lh1/ArcGIS/rest/services/Transmission_Lines/FeatureServer",
    }
  ]
};
