import { initialize, checkCurrentStatus, signIn, fetchUser } from './Outh';
import CONFIG from "../config";
const appId= CONFIG.arcGisClientId;



export default async function initAuth() {
    const oauthInfo = initialize(appId);
    let credential = await checkCurrentStatus(oauthInfo);
    if (!credential) {
        // signin
        credential = await signIn(oauthInfo);
        return;
    }

      if (credential) {  
        const portal = await fetchUser();
        return portal;
      }
}