import Browse from "../views/Browse.vue";

function getPath(route, config) {
  let path = route.params.pathMatch;
  if (config.allowExternalAccess && path.startsWith("external/")) {
    path = "/" + path;
  }
  return { path };
}

function getRoutes(config) {
  let routes = [];

  if (!config.catalogUrl) {
    routes.push({
      path: "/",
      redirect:'/video-search'
    });
    routes.push({
      path: "/search/external/(.*)",
      name: "search",
      component: () => import("../views/Search.vue"),
      props: (route) => {
        return {
          loadParent: `/external/${route.params.pathMatch}`,
        };
      },
    });
  } else {
    routes.push({
      path: "/search",
      name: "search",
      component: () => import("../views/Search.vue"),
    });
  }

  routes.push({
    path: "/video-search",
    redirect: `/video-search/external/${config.defaultStacApi}`,
  });

  routes.push({
    path: "/video-search/external/(.*)",
    name: "VideoSearch",
    component: () => import("../views/VideoSearch.vue"),
    props: (route) => {
      return {
        loadParent: `/external/${route.params.pathMatch}/`,
      };
    },
  });

  routes.push({
    path: "/custom-search",
    redirect: `/custom-search/external/${config.defaultStacApi}`,
  });
  routes.push({
    path: "/custom-search/external/(.*)",
    name: "CustomSearch",
    component: () => import("../views/CustomSearch.vue"),
    props: (route) => {
      return {
        loadParent: `/external/${route.params.pathMatch}/`,
      };
    },
  });

  routes.push({
    path: "/validation/(.*)",
    name: "validation",
    component: () => import("../views/Validation.vue"),
    props: (route) => getPath(route, config),
  });

  routes.push({
    path: "/(.*)",
    name: "browse",
    component: Browse,
    props: (route) => getPath(route, config),
  });

  return routes;
}

export default getRoutes;
