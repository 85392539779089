import Vue from "vue";
import StacBrowser from "./StacBrowser.vue";
import i18n, { loadDefaultMessages } from "./i18n";
import initAuth from "./auth/config";

export default function init() {
  return loadDefaultMessages().then(async () => {
    const portalAppItem = await initAuth();
    portalAppItem.load().then(function () {
      return new Vue({
        i18n,
        render: (h) => h(StacBrowser),
      }).$mount("#stac-browser");
    }).catch(()=> console.log('...loading'));
  });
}
