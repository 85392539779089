import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import Portal from "@arcgis/core/portal/Portal";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import CONFIG from "../config";
const portalItemId= CONFIG.arcGisPortalItemId;

export const initialize = (appId) => {
  const oauthInfo = new OAuthInfo({ appId });
  IdentityManager.registerOAuthInfos([oauthInfo]);
  return oauthInfo;
};

/* @returns Promise<void>*/
export const checkCurrentStatus = async (oauthInfo) => {
  try {
    const credential = await IdentityManager.checkSignInStatus(
      `${oauthInfo.portalUrl}/sharing`
    );
    // localStorage.setItem('token',credential.token );
    return credential;
  } catch (error) {
    console.warn(error);
  }
};

// sign in attempt
export const signIn = async (oauthInfo) => {
  try {
    const credential =
      (await checkCurrentStatus(oauthInfo)) ||
      (await fetchCredentials(oauthInfo));
    return credential;
  } catch (e) {
    const credential = await fetchCredentials(oauthInfo);
    return credential;
  }
};

export const signOut = async () => {
  IdentityManager.destroyCredentials();
  window.location.reload();
};

export const fetchCredentials = async (oauthInfo) => {
  try {
    const credential = await IdentityManager.getCredential(
      `${oauthInfo.portalUrl}/sharing`
    );
    return credential;
  } catch (e) {
    return false;
  }
};

export const fetchUser = async () => {
    const portal = new Portal();
    await portal.load();
    return portal;
};

export const fetchApiItem= async()=> {
  const apiTable = new FeatureLayer({
    portalItem: {
      id: portalItemId,
    },
    outFields: ['*'],
  });
  const apiTableResult = await apiTable.queryFeatures();
  const items = apiTableResult.features;
  const key = items.map((item) => item.toJSON().attributes.value)[0];
  return key;
};
