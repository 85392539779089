<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col :cols="12" md="12">
          <Loading v-if="loading" stretch />
          <Item v-if="isItem && isItemLoaded" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Item from "../views/Item";
import { BModal } from "bootstrap-vue";
import ErrorAlert from "../components/ErrorAlert.vue";
import Loading from "../components/Loading.vue";
import { mapState, mapGetters } from "vuex";
import CONFIG from "../config";

export default {
  components: { BModal, Item, ErrorAlert, Loading },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      isItemLoaded:false
    }
  },
  computed: {
    ...mapState(["allowExternalAccess", "url", "redirectLegacyUrls"]),
    ...mapGetters([
      "fromBrowserPath",
      "error",
      "loading",
      "collectionLink",
      "isItem",
    ]),
  },
  async mounted() {
    let url = this.$route.params.pathMatch;
    const collection = CONFIG.defaultCollection;
    if (!collection) {
      this.$emit("close");
    }
    if (url) {
      url = `${url}/collections/${collection}/items/${this.item.id}`;
    }
    url = this.fromBrowserPath(url || "/");
    await this.$store.dispatch("load", { url, show: true, loadApi: true });
    this.isItemLoaded=true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>